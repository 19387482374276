<template>
    <div class="build">
        <header class="build-header">
            <h1>互联网医院筹备与申报建设与运营</h1>
        </header>
        <div class="build-content" v-if="data.one != void 0">
            <div class="one">
                <module-title :title="data.one.title" />
                <p>{{ data.one.content }}</p>
                <ul class="trees">
                    <li v-for="(item, i) in data.one.tree" :key="i" v-html="item">
                    </li>
                </ul>
                <ul class="desc">
                    <li v-for="(item, i) in data.one.desc" :key="i">
                        {{ item }}
                    </li>
                </ul>
            </div>
            <div class="two" v-if="data.two != void 0">
                <module-title :title="data.two.title" />
                <div class="module-one">
                    <module-title :subTitle="data.two.one.title" />
                    <ul class="list">
                        <li v-for="(item, i) in data.two.one.list" :key="i">
                            <img :src="item.img" alt="" />
                            <div class="main">{{ item.title }}</div>
                            <div class="sub">{{ item.subTitle }}</div>
                        </li>
                    </ul>
                </div>
                <div class="module-two">
                    <module-title :subTitle="data.two.two.title" />
                    <ul class="list">
                        <li v-for="(item, i) in data.two.two.list" :key="i">
                            <header class="list-header">
                                <img :src="item.icon" alt="" />
                                <h3>{{ item.title }}</h3>
                            </header>
                            <ul class="inner-list">
                                <li v-for="(it, idx) in item.desc" :key="idx">
                                    <span class="index">{{ idx + 1 }}. </span>
                                    <span>{{ it }}</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="module-three">
                    <module-title :subTitle="data.two.three.title" />
                    <div class="content">
                        <img :src="data.two.three.img" alt="" />
                        <div class="box">
                            <ul class="left">
                                <li v-for="(item, i) in data.two.three.left" :key="i">
                                    <div class="left-title">{{ item.title }}</div>
                                    <div class="left-content">{{ item.content }}</div>
                                </li>
                            </ul>
                            <ul class="right">
                                <li v-for="(item, i) in data.two.three.right" :key="i">
                                    <span class="index">{{ i + 1 }}</span>
                                    <span class="content-text" v-html="item"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 互联网医院筹备与申报建设与运营
    // 组件
    import ModuleTitle from '@/components/FhiryModuleTitle';

    // vuex
    import { mapMutations } from 'vuex';

    // API
    import { getBuild } from '@/api/request';

    export default {
        name: 'build',
        created () {
            this.getBuildAjax();
        },
        data () {
            return {
                data: ''
            }
        },
        methods: {
            ...mapMutations([
                'setBusinessBanners'
            ]),
            getBuildAjax () {
                getBuild().then(res => {
                    this.setBusinessBanners(res.banners);
                    this.data = res.data;
                })
            }
        },
        components: {
            ModuleTitle
        }
    }
</script>

<style lang="less" scoped>
    .build {
        .build-header {
            padding: 20px 40px;
            border-bottom: 1px solid #ccc;
            h1 {
                font-size: 22px;
                font-weight: bold;
            }
        }
        .build-content {
            padding: 50px 40px;
            font-size: 14px;
            .one {
                margin-bottom: 100px;
                p {
                    margin: 30px 0;
                    line-height: 1.5;
                }
                .desc {
                    margin-top: 20px;
                }
            }
            .two {
                .module-one, .module-two, .module-three {
                    margin-top: 30px;
                    .list, .content {
                        margin-top: 20px;
                    }
                }
                .module-one {
                    .list {
                        display: flex;
                        align-items: flex-start;
                        li {
                            margin-right: 20px;
                            text-align: center;
                            width: 25%;
                            &:last-child {
                                margin: 0;
                            }
                            .main {
                                font-weight: bold;
                            }
                            .sub {
                                margin-top: 4px;
                                color: #777;
                                line-height: 1.5;
                            }
                        }
                    }
                }
                .module-two {
                    .list {
                        display: flex;
                        & > li {
                            flex: 1;
                            margin-right: 20px;
                            padding: 20px 14px;
                            &:first-child {
                                border: 1px solid #e42d32;
                                .list-header {
                                    h3 {
                                        color: #e42d32
                                    }
                                }
                                .index {
                                    color: #e42d32;
                                }
                            }
                            &:last-child {
                                margin-right: 0;
                                border: 1px solid #c5ac76;
                                .list-header {
                                    h3 {
                                        color: #c5ac76;
                                    }
                                }
                                .index {
                                    color: #c5ac76;
                                }
                            }
                            .list-header {
                                display: flex;
                                align-items: center;
                                margin-bottom: 14px;
                                h3 {
                                    margin-left: 10px;
                                    font-size: 20px;
                                    font-weight: bold;
                                }
                            }
                            .inner-list {
                                position: relative;
                                left: 4px;
                                li {
                                    display: flex;
                                    align-items: flex-start;
                                    margin-bottom: 4px;
                                    line-height: 1.5;
                                    color: #666;
                                    .index {
                                        position: relative;
                                        top: 1px;
                                        margin-right: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
                .module-three {
                    .content {
                        .box {
                            display: flex;
                            align-items: flex-start;
                            margin-top: 20px;
                            .left {
                                flex: 4;
                                margin-right: 20px;
                                li {
                                    margin-bottom: 20px;
                                    text-align: center;
                                    &:last-child {
                                        margin: 0;
                                    }
                                    .left-title {
                                        margin-bottom: 6px;
                                        color: #e42d32;
                                        font-size: 30px;
                                        font-weight: bold;
                                    }
                                    .left-content {
                                        display: inline-block;
                                        padding: 4px 20px;
                                        color: #FFF;
                                        background-color: #e42d32;
                                        border-radius: 15px;
                                    }
                                }
                            }
                            .right {
                                flex: 6;
                                li {
                                    display: flex;
                                    align-items: flex-start;
                                    margin-bottom: 10px;
                                    .index {
                                        position: relative;
                                        top: 2px;
                                        margin-right: 6px;
                                        padding: 1px 5px;
                                        color: #FFF;
                                        font-weight: bold;
                                        background-color: #e42d32;
                                    }
                                    .content-text {
                                        line-height: 1.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .build {
            .build-header {
                padding: 0.26666rem 0.53333rem;
                h1 {
                    font-size: 0.48rem;
                }
            }
            .build-content {
                padding: 0.8rem 0.5333rem;
                .one {
                    margin-bottom: 1.33333rem;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .build {
            .build-content {
                .two {
                    .module-one {
                        .list {
                            flex-wrap: wrap;
                            width: 100%;
                            li {
                                margin-bottom: 0.53333rem;
                                width: calc(~'50% - 10px');
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                                &:nth-last-child(-2 + n) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .module-two {
                        .list {
                            flex-direction: column;
                            & > li {
                                margin: 0 0 0.26666rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="less">
    .build {
        .trees {
            display: flex;
            & > li {
                flex: 1;
                margin-right: 20px;
                padding: 30px 20px 30px 50px;
                background-color: #eeeeee;
                &:last-child {
                    margin-right: 0;
                }
                & > ul {
                    list-style: disc;
                    & > li {
                        padding: 5px 0;
                        ul {
                            margin-top: 5px;
                            padding-left: 28px;
                            list-style: decimal inside;
                            li {
                                padding: 5px 0;
                            }
                        }
                    }
                } 
            }
        }
    }

    @media screen and (max-width: 767px) {
        .build {
            .trees {
                flex-direction: column;
                & > li {
                    margin: 0 0 0.2666rem 0; 
                }
            }
        }
    }
</style>